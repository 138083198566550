import React from "react"
import styled from "styled-components"

import SEO from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import { useTranslateValue } from "../../data/TranslateContext"

const TermsConditionsPageWrapper = styled(PageWrapper)`
  padding-top: 4rem;

  color: ${props => props.theme.color.primary};
`

const AgreementButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.whiteBg};
  border-radius: 2rem;
  font-weight: bold;
`

export default function TermsConditionsPage() {
  const [{ language }] = useTranslateValue()

  return (
    <TermsConditionsPageWrapper>
      <SEO title="Terms and Conditions" />
      <Row>
        {language === "en" && (
          <>
            <h2>Standard Sales Order Terms and Conditions</h2>

            <p>
              These Terms and Conditions apply to and govern any sales order,
              acknowledgment, invoice and transaction for the purchase of Goods,
              to the exclusion (except as specifically identified in these Terms
              and Conditions) of all other terms and conditions, including,
              without limitation, any terms or conditions which you may purport
              to apply under any purchase order, confirmation of order or
              similar document. Any such terms and conditions from you are
              objected to and disallowed. "You" or "your" shall mean the
              customer listed on the sales order to which these Standard Sales
              Order Terms and Conditions apply and are made a part of. "f'real"
              shall mean F'real! Foods, LLC. "Sales Order" shall mean your
              express, signed order to purchase the Goods, which such order
              shall be deemed to incorporate and be governed by these Terms and
              Conditions.
            </p>

            <p>
              1.{" "}
              <strong>Offer and Acceptance; Transfer of Title and Risk.</strong>{" "}
              Any Sales Order from you to f'real shall be an offer by you to
              purchase the equipment (including any part or parts thereof) and
              any services agreed upon to be purchased by you pursuant to a
              Sales Order (collectively, the "Goods") subject to these Terms and
              Conditions. No Sales Order shall be accepted until f'real either
              expressly, by giving notice of acceptance, or impliedly, by
              fulfilling the Sales Order, accepts the offer. Unless stated
              otherwise in a Sales Order, the Goods will be shipped CIP
              Destination designated on the Sales Order, and risk and title to
              all Goods sold by f'real to you shall transfer from to you upon
              f'real's delivery of the Goods to the first carrier.
            </p>

            <p>
              2. <strong>Price and Payment.</strong> You shall pay to f'real the
              price on the Sales Order immediately when due. If f'real incurs
              extra cost or suffers any loss in supplying the Goods because of a
              lack of, or defects in, instructions given by you or any other
              conduct by you, f'real may increase the price by the amount of the
              extra costs or loss without affecting any other remedy available.
              If you fail to pay the price when due, then (a) f'real may suspend
              any deliveries; and (b) f'real may charge interest on all sums due
              and outstanding at the rate of 1.5% per month or at the maximum
              amount permitted by law, whichever is less. f'real reserves the
              right, at its sole discretion, to refuse to execute, perform or
              proceed further with the execution or performance of any Sales
              Order or transaction if your arrangements for payment or credit
              appear to be or become unsatisfactory.
            </p>

            <p>
              3. <strong>Delivery.</strong> Unless otherwise stated in a Sales
              Order, delivery of the Goods shall be made to you at CIP
              Destination designated on the Sales Order. If different delivery
              terms are set forth in a Sales Order, f'real may select and engage
              carriers, truckmen, agents, and others (collectively, "Carriers")
              to transport, store, deal with and/or deliver the Goods to you,
              and unless otherwise stated in a Sales Order, delivery will be
              deemed to take place when the Goods are delivered to the first
              such Carrier. Any date of delivery specified by f'real is an
              estimate only. Time for delivery shall not be of the essence.
              Goods not delivered on a delivery date shall not give you the
              right to cancel the Sales Order, in whole or in part. You must
              make all arrangements to take delivery of Goods when they are
              tendered for delivery. If you fail to take delivery of Goods or
              fail to give f'real adequate instructions at the time stated for
              delivery of Goods: (a) risk in the Goods shall pass to you if it
              has not already passed; and (b) you shall be liable to f'real for
              all loss and damage caused by such failure; additionally, without
              affecting such rights, f'real may choose to store Goods until
              actual delivery and charge to you the reasonable costs (including
              insurance) of storage. You agree to accept delivery and pay for
              the Goods. You shall not cancel or change the accepted Sales Order
              without f'real's prior written consent.
            </p>

            <p>
              4. <strong>Quality.</strong> THE WARRANTIES SET FORTH IN THE TERMS
              AND CONDITIONS OR IN F'REAL'S WARRANTY DOCUMENTS WITH RESPECT TO
              THE GOODS ARE THE ONLY WARRANTIES MADE BY F'REAL IN CONNECTION
              WITH THE GOODS AND THE TRANSACTIONS CONTEMPLATED AS A RESULT OF
              THIS SALE. F'REAL MAKES NO OTHER WARRANTY AS TO THE GOODS OR
              SERVICES DESCRIBED HEREIN AND EXPRESSLY DISCLAIMS ALL OTHER
              WARRANTIES WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE
              IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE. F'REAL'S SOLE OBLIGATION FOR A REMEDY TO YOU SHALL BE
              REPAIR, REPLACEMENT OR REFUND OF NON-CONFORMING GOODS. YOU ASSUME
              ALL RISK WHATSOEVER AS TO THE RESULT OF THE USE OF THE GOODS
              PURCHASED, WHETHER USED ALONE OR IN COMBINATION WITH OTHER
              PRODUCTS OR SUBSTANCES. You shall inspect the Goods promptly upon
              receipt (including, but not limited to, for non-conformity for
              quantity, quality, and/or defects). Your failure to provide f'real
              with written notice of a claim within fifteen (15) days from the
              date of delivery shall constitute a waiver by you of all claims
              with respect to such Goods. If any of the Goods fail to comply
              with the provisions set forth in this Section 4, f'real may, at
              its option, repair, replace or refund the price of any Goods which
              f'real is satisfied were defective at the time of delivery,
              provided that: (a) written notice has been given to f'real within
              fifteen (15) days of the date of delivery; (b) Goods have not been
              used, processed, sold, transferred or otherwise disposed of; (c)
              no defect has arisen from your failure to follow f'real's
              instructions as to storage or usage of Goods; and (d) the
              defective Goods are held by you, at your expense, for f'real's
              inspection. You shall not be entitled to cancel the Sales Order as
              a result of delivery of defective Goods. It shall be your
              responsibility to pursue claims against the carrier for Goods
              which have been lost or damaged in transit.
            </p>

            <p>
              5. <strong>f'real's Liability, Indemnity, Remedies.</strong>{" "}
              Without prejudice to the effect or operation of any of these Terms
              and Conditions, f'real's aggregate liability for any loss or
              damage of any nature, including, but not limited to, claims for
              indemnification, whether arising out of f'real's breach of
              contract, negligence, breach of statutory duty or otherwise shall
              be limited to and shall not exceed a sum equal to the price of the
              Goods. f'real shall not be liable to you in contract, tort or
              otherwise, for the cost of any labor or machine time or for any
              loss of profit, loss of business or depletion of goodwill, and in
              each case, whether direct, indirect or consequential, whatsoever
              and howsoever caused, which arise out of or in connection with the
              sale of Goods. In no event shall f'real be liable for or have any
              responsibility for any misuse of the Goods or any action or
              omission regarding the Goods in violation of any manual, labels,
              warnings or instructions to the Goods. f'real shall not be
              responsible for any actions taken or fines or penalties assessed
              by any governmental agency against shipment of the Goods because
              of your failure to comply with any laws or regulations or with a
              notification issued to you by any such governmental agency.
              Nothing in this clause shall operate to restrict or exclude
              f'real's liability for any matter which it would be illegal to
              restrict or exclude or attempt to restrict or exclude. Indemnity.
              You shall save, defend, indemnify and hold harmless f'real, and
              its successors, assigns and agents, affiliated, associated, parent
              and subsidiary companies and its officers, directors, agents and
              employees (the "Seller Parties") from and against any and all
              liabilities, losses, damages, claims, injury, actions,
              proceedings, costs and expenses ("Claims"), including without
              limitation loss of profit, loss of business, depletion of
              goodwill, legal fees and other professional fees and expenses,
              awarded against or incurred or paid by any of f'real or the Seller
              Parties as a result of or in connection with your breach of these
              Terms and Conditions, negligence, breach of statutory duty,
              improper use, handling or maintenance of the Goods (including,
              without limitation, in violation of any manual, instructions,
              warnings or labels) or other misconduct. Remedies. If you breach
              any of these Terms and Conditions, f'real shall have, in addition
              to other rights specified herein, all of the rights provided for
              under the Uniform Commercial Code as applied in New York or other
              applicable law. Each right or remedy of f'real shall be
              cumulative, non-exclusive and without prejudice to any other right
              or remedy of f'real to the extent permitted by law. You
              acknowledge that a breach of this agreement may not be adequately
              remedied by monetary damages and f'real shall have available any
              and all equitable remedies, including injunctive relief and
              specific performance.
            </p>

            <p>
              6. <strong>Confidentiality; f'real's Property.</strong> You shall
              keep in strict confidence all technical or commercial know-how,
              specifications, inventions, formulas, manufacturing processes,
              financial information, initiatives or similar documents, products
              or information which are of a confidential nature and have been
              disclosed to you by f'real or its agents and any other
              confidential information concerning f'real's business or its
              products which you may obtain ("Confidential Information"), and
              you shall restrict disclosure of such Confidential Information to
              your employees, agents, or sub-contractors as need to know the
              same and shall ensure that they are subject to the same
              obligations of confidentiality as bind you. Your obligation of
              confidentiality and non-use for Confidential Information hereunder
              shall continue beyond performance of the Sales Order. Materials,
              formulas, copyright, design rights, or any other forms of
              intellectual property rights in all specifications and data
              supplied by f'real, shall, at all times, be and remain the
              exclusive property of f'real but shall be held by you in safe
              custody at your risk and maintained and kept in good condition by
              you until returned to f'real. The f'real foods Cellular Modem
              Agreement for FRLB4 & FRLB6 between you and f'real is incorporated
              herein by reference.
            </p>

            <p>
              7. <strong>Miscellaneous. Force Majeure.</strong> If f'real is
              prevented, hindered or delayed from manufacturing or delivering
              the Goods by reason of any cause beyond f'real's control, f'real
              may give notice in writing to you either (a) canceling f'real's
              outstanding obligations or (b) extending the time for delivery of
              Goods by such period as f'real may reasonably require. f'real will
              not be liable to you for any damage or loss of any nature, whether
              direct or indirect, or any late deliveries, as a result of giving
              such notice. Assignment. You may not assign the Sales Order or
              these Terms and Conditions or any part thereof without f'real's
              prior, express written consent. Notice. Any notice required or
              permitted to be given by either party under these Terms and
              Conditions shall be in writing and shall be delivered or sent by
              pre-paid post sent first class addressed to the party as indicated
              on the Sales Order, or such other address as may be notified by
              that party, pursuant to these Terms and Conditions. Any notice
              which is delivered shall be deemed to be served when handed to the
              addressee and any notice sent by pre-paid post first class shall
              be deemed to be served two business days after posting. Governing
              Law; Venue; Severability. These Terms and Conditions, the
              Contract, and all transactions contemplated hereby shall be
              governed and construed in all respects in accordance with the laws
              of the State of New York without regard to its conflicts of law
              rules. The parties agree to submit to the jurisdiction of any
              state or federal court in Erie County in the State of New York in
              any action or dispute resolution process arising out of the terms,
              enforcement or breach of the Contract or these Terms and
              Conditions. Any provision or provisions of the Contract which are
              found to be invalid shall be deemed inoperative without
              invalidating or otherwise affecting any other provisions of the
              Contract. Modification and Waiver. No amendment, variation,
              waiver, alteration, or modification to the Contract and these
              Terms and Conditions shall have effect unless expressly agreed
              upon in writing and signed by f'real. Failure or delay by f'real
              in enforcing or partially enforcing any provision of these Terms
              and Conditions shall not be construed as a waiver of any of
              f'real's rights. Any waiver by f'real of any breach of, or any
              default under, any provision of the Terms and Conditions by you
              shall not be deemed a waiver of any subsequent breach or default
              and shall in no way affect the other terms of the Terms and
              Conditions. Entire Agreement. These Terms and Conditions and the
              Sales Order constitute the entire agreement between the parties
              and you acknowledge that you have not relied on any
              representations relating to the Goods or these Terms and
              Conditions made by any of f'real's directors, employees or agents.
              Any course of prior dealings, promise or condition in connection
              herewith, or usage of trade not incorporated herein, shall not be
              binding upon either party.
            </p>

            <p>
              <AgreementButton
                href="/assets/pdf/freal-Standard-Sales-Order-Terms-and-Conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Agreement
              </AgreementButton>
            </p>
          </>
        )}

        {language === "fr" && (
          <>
            <h2>Conditions générales</h2>

            <p>
              Les présentes conditions générales s’appliquent à tout bon de
              commande et accusé de réception et à toute facture et opération
              visant l’achat de produits et les régissent, et excluent (sauf
              dans les cas expressément prévus aux présentes) toutes les autres
              conditions générales, notamment celles que vous pouvez prétendre
              appliquer aux termes d’un bon de commande, d’une confirmation de
              commande ou d’un document similaire. Toute condition générale de
              ce type émanant de vous sera contestée et rejetée. Dans les
              présentes, « vous », « votre » ou « vos » désignent le client
              nommé dans le bon de commande auquel les présentes conditions
              générales de vente standard s’appliquent et dont elles font partie
              intégrante; « f’real » désigne F’real! Foods, LLC; « bon de
              commande » désigne le bon exprès et signé visant l’achat des
              produits, bon qui est réputé incorporer les présentes conditions
              générales et être régi par celles-ci.
            </p>

            <p>
              1.{" "}
              <strong>
                Offre et acceptation; transfert du titre de propriété et du
                risque.
              </strong>{" "}
              Tout bon de commande que vous soumettez à f’real constitue une
              offre de votre part d’acheter le matériel (y compris toute pièce
              ou toutes pièces de celui-ci) et tous les services que vous
              convenez d’acheter conformément à un bon de commande
              (collectivement, les « produits ») sous réserve des présentes
              conditions générales. Aucun bon de commande ne sera accepté tant
              que f’real n’aura pas accepté l’offre, soit expressément, en
              remettant un avis d’acceptation, soit implicitement, en exécutant
              le bon de commande. Sauf indication contraire dans un bon de
              commande, les produits seront expédiés port et assurance payés à
              la destination indiquée sur le bon de commande, et le risque et le
              titre de propriété afférents à tous les produits que f’real vous
              aura vendus vous seront transférés au moment où f’real livre les
              produits au premier transporteur.
            </p>

            <p>
              2. <strong>Prix et paiement.</strong> Vous devez payer à f’real le
              prix indiqué sur le bon de commande immédiatement à l’échéance du
              délai de paiement. Si f’real engage des frais supplémentaires ou
              subit une perte au moment de la fourniture des produits en raison
              d’un manque d’instructions de votre part, d’erreurs dans les
              instructions que vous lui aurez transmises ou de toute autre
              conduite de votre part, f’real pourra augmenter le prix du montant
              des frais supplémentaires ou de la perte sans pour autant porter
              atteinte à ses autres recours ouverts en droit. Si vous ne payez
              pas le prix à l’échéance du délai de paiement, alors a) f’real
              pourra suspendre les livraisons et b) f’real pourra facturer des
              intérêts sur toutes les sommes dues et impayées au taux de 1,5 %
              par mois ou au taux maximal autorisé par la loi, si celui-ci est
              inférieur. f’real se réserve le droit, à sa seule discrétion, de
              refuser d’exécuter tout bon de commande ou toute opération, de
              réaliser celui-ci ou celle-ci, ou encore d’en poursuivre
              l’exécution ou la réalisation si vos dispositions en matière de
              paiement ou de crédit semblent être ou devenir insatisfaisantes.
            </p>

            <p>
              3. <strong>Livraison.</strong> Sauf indication contraire dans un
              bon de commande, les produits vous seront livrés port et assurance
              payés à la destination indiquée sur le bon de commande. Si un bon
              de commande prévoit des conditions de livraison différentes,
              f’real pourra sélectionner et engager des transporteurs,
              camionneurs, agents et autres (collectivement, les « transporteurs
              ») pour transporter, entreposer, traiter et/ou vous livrer les
              produits, et sauf indication contraire dans un bon de commande, la
              livraison sera réputée avoir lieu lorsque les produits sont livrés
              au premier de ces transporteurs. Toute date de livraison indiquée
              par f’real n’est qu’une estimation. Le délai de livraison ne
              constitue pas une condition essentielle. La non-livraison des
              produits à une date de livraison ne vous donne pas le droit
              d’annuler le bon de commande, en totalité ou en partie. Vous devez
              prendre toutes les dispositions nécessaires pour prendre livraison
              des produits à leur livraison. Si vous ne prenez pas livraison des
              produits ou ne donnez pas d’instructions adéquates à f’real au
              moment indiqué pour la livraison des produits : a) le risque
              afférent aux produits vous sera transféré s’il ne l’a pas déjà été
              et b) vous serez redevable envers f’real de toutes les pertes et
              de tous les dommages causés par ce manquement; en outre, sans
              porter atteinte à ces droits, f’real peut choisir d’entreposer les
              produits jusqu’à ce qu’ils soient effectivement livrés et vous
              facturer les frais raisonnables (y compris l’assurance) de
              l’entreposage. Vous acceptez de prendre livraison et de payer les
              produits. Vous n’annulerez ni ne modifierez le bon de commande
              accepté sans le consentement écrit préalable de f’real.
            </p>

            <p>
              4. <strong>Qualité.</strong> LES GARANTIES ÉNONCÉES DANS LES
              CONDITIONS GÉNÉRALES OU DANS LES DOCUMENTS DE GARANTIE DE F’REAL
              CONCERNANT LES PRODUITS SONT LES SEULES GARANTIES DONNÉES PAR
              F’REAL RELATIVEMENT AUX PRODUITS ET AUX OPÉRATIONS ENVISAGÉES PAR
              SUITE DE CETTE VENTE. F’REAL NE DONNE AUCUNE AUTRE GARANTIE QUANT
              AUX PRODUITS OU SERVICES DÉCRITS DANS LES PRÉSENTES ET DÉCLINE
              EXPRESSÉMENT TOUTE AUTRE GARANTIE, QU’ELLE SOIT EXPRESSE,
              IMPLICITE OU PRÉVUE PAR LA LOI, Y COMPRIS LES GARANTIES IMPLICITES
              DE QUALITÉ MARCHANDE ET D’ADÉQUATION À UN USAGE PARTICULIER. LA
              SEULE OBLIGATION DE F’REAL ENVERS VOUS À TITRE DE RÉPARATION SERA
              LA RÉPARATION, LE REMPLACEMENT OU LE REMBOURSEMENT DES PRODUITS
              NON CONFORMES. VOUS ASSUMEZ TOUS LES RISQUES, QUELS QU’ILS SOIENT,
              DÉCOULANT DE L’UTILISATION DES PRODUITS ACHETÉS, QU’ILS SOIENT
              UTILISÉS SEULS OU EN COMBINAISON AVEC D’AUTRES PRODUITS OU
              SUBSTANCES. Vous devez inspecter les produits sans délai après
              leur réception (notamment en ce qui concerne leur non-conformité
              en termes de quantité, de qualité et/ou de défauts). Si vous ne
              remettez à f’real aucun avis écrit de réclamation dans les quinze
              (15) jours suivant la date de livraison, c’est dire que vous
              renoncez à toutes les réclamations relatives aux produits. Si l’un
              des produits n’est pas conforme aux dispositions énoncées au
              présent article 4, f’real peut, à sa discrétion, réparer ou
              remplacer tout produit dans le cas duquel f’real a la conviction
              qu’il était défectueux au moment de la livraison ou en rembourser
              le prix, à condition : a) que vous remettiez un avis écrit à
              f’real dans les quinze (15) jours suivant la date de livraison, b)
              que vous n’ayez pas utilisé, transformé, vendu, transféré ou
              autrement cédé les produits, c) qu’aucun défaut n’ait résulté de
              votre manquement à suivre les instructions de f’real concernant
              l’entreposage ou l’utilisation des produits et d) que vous
              conserviez les produits défectueux, à vos frais, en vue de leur
              inspection par f’real. Vous ne pouvez pas annuler le bon de
              commande par suite de la livraison de produits défectueux. Il vous
              incombe de faire valoir vos droits à l’encontre du transporteur
              pour les produits qui ont été perdus ou endommagés pendant le
              transport.
            </p>

            <p>
              5.{" "}
              <strong>
                Responsabilité de f’real, indemnisation et recours.
              </strong>{" "}
              Sans qu’il soit préjugé de l’effet ou du fonctionnement des
              présentes conditions générales, la responsabilité globale de
              f’real relativement à toute perte ou à tout dommage, de quelque
              nature que ce soit, y compris toute demande d’indemnisation,
              résultant du fait que f’real a violé un contrat, manqué à une
              obligation prévue par la loi ou commis un acte de négligence ou
              une autre faute, se limitera au prix des produits. f’real n’aura
              envers vous aucune responsabilité de nature contractuelle,
              délictuelle ou autre pour le coût de la main-d’œuvre ou du temps
              machine ou pour toute perte de profit, perte de parts de marché ou
              réduction de valeur du fonds de commerce, et dans chaque cas,
              qu’il s’agisse de pertes directes, indirectes ou consécutives,
              quelles qu’elles soient et quelle qu’en soit la cause, qui
              découlent de la vente des produits ou s’y rapportent. En aucun cas
              f’real ne sera responsable de la mauvaise utilisation des produits
              ou d’un acte ou d’une omission concernant les produits en
              violation de tout manuel ou avertissement ou de toute étiquette ou
              instruction concernant les produits. f’real ne sera pas
              responsable des actions prises ou des amendes ou pénalités
              imposées par un organisme gouvernemental à l’encontre de
              l’expédition des produits parce que vous avez omis de respecter
              une loi ou un règlement ou un avis qu’un tel organisme
              gouvernemental vous aurait remis. Aucune disposition du présent
              article n’a pour effet de restreindre ou d’exclure la
              responsabilité de f’real pour toute question qu’il serait illégal
              de restreindre ou d’exclure ou de tenter de restreindre ou
              d’exclure. Indemnisation. Vous indemniserez f’real, ses
              successeurs et ayants droit, les sociétés membres de son groupe,
              les sociétés qui ont un lien avec elle, ses sociétés mères et ses
              filiales ainsi que ses dirigeants, administrateurs, mandataires et
              employés (les « parties du vendeur ») et les tiendrez à couvert
              des responsabilités, pertes, dommages-intérêts, réclamations,
              préjudices, actions, procédures et frais (les « réclamations »), y
              compris les pertes de profit, les pertes de parts de marché, les
              réductions de valeur du fonds de commerce, les frais juridiques et
              autres honoraires et débours de professionnels, adjugés à
              l’encontre de f’real ou des parties du vendeur ou encourus ou
              payés par f’real ou les parties du vendeur du fait ou relativement
              au fait que vous avez violé les présentes conditions générales,
              manqué à une obligation prévue par la loi ou commis un acte de
              négligence, ou utilisé, manipulé ou entretenu de façon
              inappropriée les produits (notamment en violation d’un manuel,
              d’instructions, d’avertissements ou d’étiquettes) ou encore commis
              toute autre faute. Recours. Si vous violez l’une de présentes
              conditions générales, f’real aura, en plus des autres droits
              stipulés dans les présentes, tous les droits prévus par le code
              américain intitulé Uniform Commercial Code, tel qu’il s’applique
              dans l’État de New York, ou par toute autre loi applicable. Chaque
              droit ou recours de f’real sera cumulatif et non exclusif et ne
              portera pas atteinte aux autres droits ou recours de f’real dans
              la mesure permise par la loi. Vous reconnaissez qu’en cas de
              violation du présent contrat, des dommages-intérêts pécuniaires ne
              sauraient constituer réparation adéquate, et f’real disposera de
              tous les recours équitables, y compris l’injonction et l’exécution
              en nature.
            </p>

            <p>
              6. <strong>Confidentialité; propriété de f’real.</strong> Vous
              garderez strictement confidentiels l’ensemble des connaissances
              techniques ou commerciales, des devis, des inventions, des
              formules, des procédés de fabrication, des informations
              financières, des initiatives ou des documents, produits ou
              renseignements similaires qui sont de nature confidentielle et qui
              vous ont été communiqués par f’real ou ses mandataires et des
              autres renseignements confidentiels concernant l’entreprise de
              f’real ou ses produits que vous pourriez obtenir (les «
              renseignements confidentiels »), et vous limiterez la
              communication de ces renseignements confidentiels à vos employés,
              mandataires ou sous-traitants qui ont besoin de les connaître et
              vous vous assurerez qu’ils sont soumis aux mêmes obligations de
              confidentialité que vous. Votre obligation de confidentialité et
              de non-utilisation des renseignements confidentiels en vertu des
              présentes sera maintenue après l’exécution du bon de commande. Les
              documents, formules, droits d’auteur, droits de conception ou
              toutes autres formes de droits de propriété intellectuelle dans
              tous les devis et toutes les données fournis par f’real seront, à
              tout moment, et resteront la propriété exclusive de f’real, mais
              vous les conserverez sous bonne garde à vos risques et vous les
              maintiendrez et les garderez en bon état jusqu’à ce que vous les
              retourniez à f’real. Le contrat de modem cellulaire de f’real
              relatif à FRLB4 et à FRLB6 conclu entre vous et f’real est intégré
              aux présentes par renvoi.
            </p>

            <p>
              7. <strong>Dispositions diverses.</strong> Cas de force majeure.
              Si, en raison d’une cause indépendante de sa volonté, f’real est
              dans l’impossibilité de fabriquer ou de livrer les produits ou de
              le faire dans les délais prévus, elle peut vous remettre un avis
              écrit soit a) annulant les obligations qui lui incombent et
              qu’elle n’a pas exécutées, soit b) prolongeant le délai de
              livraison des produits du nombre de jours que f’real peut
              raisonnablement exiger. f’real ne sera pas responsable envers vous
              des dommages ou des pertes de quelque nature que ce soit, qu’ils
              soient directs ou indirects, ou de tout retard de livraison, par
              suite de l’envoi d’un tel avis. Cession. Vous ne pouvez pas céder
              tout ou partie du bon de commande ou des présentes conditions
              générales sans le consentement écrit préalable et exprès de
              f’real. Avis. Tout avis requis ou autorisé par l’une ou l’autre
              des parties aux termes des présentes conditions générales doit
              être fait par écrit et remis en mains propres ou envoyé par
              courrier prépayé de première classe adressé à la partie indiquée
              sur le bon de commande, ou à toute autre adresse qui peut être
              indiquée par cette partie au moyen d’un avis, conformément aux
              présentes conditions générales. Tout avis qui est livré en mains
              propres est considéré comme signifié lorsqu’il est remis au
              destinataire et tout avis envoyé par courrier prépayé de première
              classe est considéré comme signifié deux jours ouvrables après sa
              mise à la poste. Droit applicable; tribunaux compétents;
              dissociabilité des dispositions. Les présentes conditions
              générales, le contrat et toutes les opérations envisagées par les
              présentes sont régis par les lois de l’État de New York, sans
              égard aux principes de conflit de lois, et doivent être
              interprétés à tous égards conformément à celles-ci. Les parties
              acceptent de se soumettre à la compétence de tout tribunal d’État
              ou fédéral du comté d’Erie dans l’État de New York dans le cadre
              de toute action ou procédure de règlement des différends découlant
              des conditions, de l’exécution ou de la violation du contrat ou
              des présentes conditions générales. Toute disposition du contrat
              jugée invalide sera considérée comme inopérante sans invalider ou
              modifier d’une autre manière les autres dispositions du contrat.
              Modification et renonciation. Aucune modification apportée au
              contrat et aux présentes conditions générales ni aucune
              renonciation à l’application de leurs dispositions n’auront
              d’effet à moins d’être expressément convenues par écrit et signées
              par f’real. Si f’real omet d’appliquer ou tarde à appliquer, en
              totalité ou en partie, l’une ou l’autre des dispositions des
              présentes conditions générales, une telle omission ou un tel
              retard ne sauraient être interprétés comme une renonciation de la
              part de f’real à exercer l’un de ses droits. Si f’real renonce à
              l’application de ses droits en cas de manquement de votre part à
              une disposition des présentes conditions générales, une telle
              renonciation ne saurait être considérée comme s’appliquant à tout
              manquement ultérieur et n’a aucune incidence sur les autres
              dispositions des conditions générales. Entente intégrale. Les
              présentes conditions générales et le bon de commande constituent
              l’intégralité de l’entente entre les parties, et vous reconnaissez
              que vous ne vous êtes pas fié aux déclarations relatives aux
              produits ou aux présentes conditions générales faites par l’un des
              directeurs, employés ou mandataires de f’real. Toute transaction,
              promesse ou condition antérieure en rapport avec les présentes ou
              tout usage du commerce qui n’est pas intégré dans les présentes ne
              lieront aucune des parties.
            </p>

            <p>
              <AgreementButton
                href="/assets/pdf/freal-Standard-Sales-Order-Terms-and-Conditions_fr.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Télécharger le contrat
              </AgreementButton>
            </p>
          </>
        )}
      </Row>
    </TermsConditionsPageWrapper>
  )
}
